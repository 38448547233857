import React, { Component, CSSProperties } from "react";
import PropTypes from "prop-types";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "./ChallengeOverview.scss";
import {
  ChallengeApi,
  ChallengeDetail,
  ChallengeEntryApi,
  ChallengeEntryDetail,
  PushnotificationsApi,
} from "../../api/generated";
import {
  getDates,
  getDateTimeDifference,
  getFormatedDateString,
  getToDateForChallenge,
} from "../../helper/datetime";
import {
  colorClassNameForDifficulty,
  nameForDifficulty,
} from "../../helper/difficulty";
import { getInitialsFromUsername } from "../../helper/user";
import {
  difficultyForChallengeEntry,
  formatXLabels,
  formatYLabels,
} from "../../helper/rechart_helper";
import { withRouter } from "react-router";
import { AxiosError } from "axios";

import trioKombiLogoWeiter from "../../assets/klimaKombiLogo_alps_sos.png";
import { Link, NavLink } from "react-router-dom";
import {
  PushNotificationsData,
  registerServiceWorkerAndSubscribePushAPI,
} from "../../helper/pushnotifications";
import {
  ChallengeSettings,
  challengeSettingsPushAllowed,
  removeDuplicatesFromChallengeEntries,
  setPushNotificationsStatusForChallenge,
} from "../../helper/challenge";

interface CountdownCSSValue extends CSSProperties {
  "--value": number;
}

interface ChartEntry {
  date: Date;
  difficulty?: number;
}

type ChallengeOverviewState = {
  days: number;
  hours: number;
  min: number;
  sec: number;
  challengeEntries: ChallengeEntryDetail[];
  challenge: ChallengeDetail;
  challengeHistoryData: ChartEntry[];
  challengeEntryForCurrentDateExists: boolean;
  circleDisplayText: string;
  modalVisisble: boolean;
};

export class ChallengeOverview extends Component<any, ChallengeOverviewState> {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  setIntervalCountdownID: number = 0;
  modalDismissed: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      challengeEntries: [],
      challenge: {
        challenge_id: "",
        description: "",
        name: "",
        start_date: "2021",
        username: "",
        id: "",
        duration: 30,
      },
      challengeHistoryData: [],
      challengeEntryForCurrentDateExists: false,
      circleDisplayText: "",
      modalVisisble: false,
    } as ChallengeOverviewState;
    this.pushNotificationsDone = this.pushNotificationsDone.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let challengeApi = new ChallengeApi();
    challengeApi
      .challengeRead(match.params.challenge_id)
      .then((challenge) => {
        // Cast ID as any.
        // ID has to best in this case.
        // Otherwise the request would have failed
        this.fetchChallengeEntryDate(challenge.data.challenge_id as string);
        this.setState((state, _) => ({
          challenge: challenge.data,
        }));

        this.checkPushPermissions();
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError) {
          if (error.response?.status === 404) {
            this.props.history.push("/not-found");
          }
        } else {
          console.error("Something went wrong with this request");
        }
      });

    this.setIntervalCountdownID = window.setInterval(() => {
      let dateDifference = getDateTimeDifference(
        new Date(this.state.challenge.start_date),
        this.state.challenge.duration as number
      );

      if (dateDifference.days > (this.state.challenge.duration as number)) {
        // Challenge start date is in the future
        dateDifference.days = 30;
        dateDifference.hours = 0;
        dateDifference.minutes = 0;
        dateDifference.seconds = 0;
        this.setState({
          circleDisplayText: "Deine Challenge hat noch nicht gestartet",
        });
      } else if (dateDifference.days < 0) {
        // Challenge is already over
        dateDifference.days = 0;
        dateDifference.hours = 0;
        dateDifference.minutes = 0;
        dateDifference.seconds = 0;
        this.setState({
          circleDisplayText: "Du hast deine Challenge abgeschlossen!",
        });
      }
      this.setState({
        sec: dateDifference.seconds,
        min: dateDifference.minutes,
        hours: dateDifference.hours,
        days: dateDifference.days,
      });
    }, 1000);
  }

  isButtonDisabled(): boolean {
    let dateDifference = getDateTimeDifference(
      new Date(this.state.challenge.start_date),
      this.state.challenge.duration as number
    );

    // Duplicated code from component did mount!
    // Check if challenge has not started yet
    if (dateDifference.days > (this.state.challenge.duration as number)) {
      return true;
    }
    // Check if challenge is already over
    else if (dateDifference.days < 0) {
      return true;
    }
    // There is already an entry for today
    else if (this.state.challengeEntryForCurrentDateExists) {
      return true;
    }
    // Check if there is already an entry for today
    return false;
  }

  async checkPushPermissions() {
    let pushPermissionState: PermissionStatus =
      await navigator.permissions.query({
        name: "notifications",
      });

    console.log(pushPermissionState);

    // Check if user has disabled notifications for this challenge
    const { match } = this.props;
    if (!challengeSettingsPushAllowed(match.params.challenge_id)) {
      // Push notifications disabled!
      return;
    }

    if (pushPermissionState.state === "granted") {
      // If push device is not registered for this challenge --> display modal
      const api = new PushnotificationsApi({
        isJsonMime: (mime) => false,
      });
      registerServiceWorkerAndSubscribePushAPI(
        process.env.REACT_APP_APPLICATION_SERVER_KEY as string,
        this.state.challenge.username,
        this.pushNotificationsDone
      );
    } else if (pushPermissionState.state === "prompt") {
      // Ask user to allow push notifications
      // Display modal and ask user
      this.setState({ modalVisisble: true });
    } else if (pushPermissionState.state === "denied") {
      // Well there is nothing we can do so far
    }
  }

  async pushNotificationsDone(data: PushNotificationsData) {
    // Show modal if: Permissions are prompt

    // User allowed push notifications
    // Add user to push notifications
    const api = new PushnotificationsApi({
      isJsonMime: (mime) => false,
    });

    // Check if modal was not yet dismissed AND device is not yet registered
    if (!this.modalDismissed) {
      let result = await api.pushnotificationsExistsCreate(
        this.state.challenge.challenge_id as string,
        data
      );
      if (result.data.is_registered) {
        return;
      } else {
        this.setState({ modalVisisble: true });
        return;
      }
    }

    api
      .pushnotificationsChallengeCreate(
        this.state.challenge.challenge_id as string,
        {
          auth: data.auth,
          p256dh: data.p256dh,
          registration_id: data.registration_id,
          browser: data.browser,
          name: data.name,
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async fetchChallengeEntryDate(challengeID: string) {
    let challengeEntryApi = new ChallengeEntryApi();
    challengeEntryApi
      .challengeEntryListRead(challengeID)
      .then((challengeEntries) => {
        challengeEntries.data = removeDuplicatesFromChallengeEntries(
          challengeEntries.data,
          this.state.challenge
        );
        // Update challenge entries
        this.setState((state, _) => ({
          challengeEntries: challengeEntries.data,
        }));

        // Update challenge history
        const dates = getDates(
          new Date(this.state.challenge.start_date),
          this.state.challenge.duration as number
        );

        let data: ChartEntry[] = [];
        if (difficultyForChallengeEntry(challengeEntries.data, new Date())) {
          this.setState({ challengeEntryForCurrentDateExists: true });
        }
        for (let date of dates) {
          data.push({
            date: new Date(date),
            difficulty: difficultyForChallengeEntry(
              challengeEntries.data,
              date
            ),
          });
        }
        this.setState((state, _) => ({
          challengeHistoryData: [...state.challengeHistoryData, ...data],
        }));
      });
  }

  componentWillUnmount() {
    window.clearInterval(this.setIntervalCountdownID);
  }

  pushNotificationsModalDismiss(allowPushNotifications: boolean) {
    this.modalDismissed = true;
    this.setState({ modalVisisble: false });
    const { match } = this.props;
    console.log(match.params.challenge_id);

    setPushNotificationsStatusForChallenge(
      match.params.challenge_id,
      allowPushNotifications
    );

    if (allowPushNotifications) {
      registerServiceWorkerAndSubscribePushAPI(
        process.env.REACT_APP_APPLICATION_SERVER_KEY as string,
        this.state.challenge.username,
        this.pushNotificationsDone
      );
    }
  }

  render() {
    return (
      <div>
        <input
          type="checkbox"
          id="my-modal-2"
          className="modal-toggle"
          checked={this.state.modalVisisble}
          onChange={() => {}}
        />
        {/* Allow notifications modal */}
        <div className="modal">
          <div className="modal-box">
            <p className="text-xl text-center">
              Dürfen wir dir solange die Challenge läuft eine Erinnerung für
              deinen Tageseintrag senden?
            </p>
            <div className="modal-action">
              <label
                onClick={() => this.pushNotificationsModalDismiss(true)}
                className="btn btn-alps"
              >
                Natürlich, los geht's!
              </label>
              <label
                onClick={() => this.pushNotificationsModalDismiss(false)}
                className="btn btn-ghost border-gray-50"
              >
                Nein Danke
              </label>
            </div>
          </div>
        </div>{" "}
        <NavLink to={"/"}>
          <img
            className="trioKombiLogo"
            src={trioKombiLogoWeiter}
            alt="Challenge Logo der 30 Days Klima Challenge"
          />
        </NavLink>
        <div className="bg-gray-50 rounded-2xl p-4 flex flex-col">
          <div className="flex pl-4 pt-4 pr-4">
            <div className="flex flex-col">
              <h4 className="text-3xl">{this.state.challenge.username}</h4>
              <div className="font-bold tracking-wider text-green-900 mt-auto text-gray-400">
                {this.state.challenge.challenge_id}
              </div>
            </div>

            <div className="ml-auto">
              <div className="avatar placeholder">
                <div className="bg-primary text-neutral-content rounded-full w-16 h-16">
                  <span className="bg-firstly text-2xl">
                    {getInitialsFromUsername(this.state.challenge.username)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-4 mb-8 border-gray-400" />
          <p className="text-gray-600 uppercase font-bold text-l text-center">
            30Daychallenge
          </p>
          <p className="text-center text-2xl mt-4 mb-8">
            {this.state.challenge.name}
          </p>
          {/* Radial Progressbar */}
          <div className="sm:w-2/3 w-10/12 text-center mt-8 self-center">
            <CircularProgressbarWithChildren
              // Computing: (1 - (currentNumberOfDays / totalNumberOfDays)) * 100
              value={Math.trunc(
                (1 -
                  this.state.days / (this.state.challenge.duration as number)) *
                  100
              )}
            >
              {this.state.circleDisplayText ? (
                <p className="px-4">{this.state.circleDisplayText}</p>
              ) : (
                <p className="px-4">
                  Super! <br /> Du hast bereits{" "}
                  <b>
                    {" "}
                    {Math.trunc(
                      (1 -
                        this.state.days /
                          (this.state.challenge.duration as number)) *
                        100
                    )}{" "}
                    %{" "}
                  </b>{" "}
                  geschafft.{" "}
                </p>
              )}
              <p className="text-3xl font-bold">
                {/* Tag {(this.state.challenge.duration as number) - this.state.days} */}
              </p>
            </CircularProgressbarWithChildren>
          </div>
          {/* Countdown */}
          <p className="text-gray-600 uppercase font-bold text-l mt-16 text-center">
            Verbleibende Zeit
          </p>

          <div className="grid grid-flow-col gap-5 text-center auto-cols-max mt-4 justify-center">
            <div className="flex flex-col">
              <span className="font-mono text-5xl countdown">
                <span
                  style={{ "--value": this.state.days } as CountdownCSSValue}
                ></span>
              </span>
              Tage
            </div>
            <div className="flex flex-col">
              <span className="font-mono text-5xl countdown">
                <span
                  style={{ "--value": this.state.hours } as CountdownCSSValue}
                ></span>
              </span>
              Stunden
            </div>
            <div className="flex flex-col">
              <span className="font-mono text-5xl countdown">
                <span
                  style={{ "--value": this.state?.min } as CountdownCSSValue}
                ></span>
              </span>
              Minuten
            </div>
            <div className="flex flex-col">
              <span className="font-mono text-5xl countdown">
                <span
                  style={{ "--value": this.state.sec } as CountdownCSSValue}
                ></span>
              </span>
              sec
            </div>
          </div>
          {/* Overview `Übersicht` */}
          <p className="text-gray-600 uppercase font-bold text-l mt-20 text-center">
            Übersicht
          </p>
          {/* Time period of challenge */}
          <p className="text-md mt-4 mr-8 text-right">
            {getFormatedDateString(this.state.challenge.start_date)} -{" "}
            {getFormatedDateString(
              getToDateForChallenge(
                new Date(this.state.challenge.start_date),
                this.state.challenge.duration as number
              ).toDateString()
            )}
          </p>
          {/* Linechart */}
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={this.state.challengeHistoryData}
              height={400}
              width={400}
              margin={{ top: 20, left: 20, right: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="1 1" />

              <XAxis dataKey="date" tickFormatter={formatXLabels} />
              <YAxis tickFormatter={formatYLabels} ticks={[25, 50, 75, 100]} />
              <Line
                dataKey="difficulty"
                connectNulls
                type="monotone"
                stroke="#8884d8"
                strokeWidth="2px"
                fill="#8884d8"
                dot={true}
              />
            </LineChart>
          </ResponsiveContainer>
          {/* Dailiy Entries */}
          <p className="text-gray-600 uppercase font-bold text-l mt-20 text-center ml-8 mb-4">
            Tageseinträge
          </p>
          {this.state.challengeEntries.map((challengeEntry, i) => {
            return (
              <div
                tabIndex={0}
                className="collapse border border-base-300 collapse-arrow self-center w-11/12 last:mb-60"
              >
                <div className="collapse-title text-l font-medium flex flex-row">
                  <div className="select-none">
                    {getFormatedDateString(challengeEntry.date)}
                  </div>
                  <div
                    className={`whitespace-nowrap badge ml-auto mr-6 self-center select-none ${colorClassNameForDifficulty(
                      challengeEntry.difficulty,
                      challengeEntry.cheat_day
                    )}`}
                  >
                    {nameForDifficulty(
                      challengeEntry.difficulty,
                      challengeEntry.cheat_day
                    )}
                  </div>
                </div>
                <div className="collapse-content">
                  <p>{challengeEntry.diary_entry}</p>
                </div>
              </div>
            );
          })}
          <Link to={`/challenge/entry/${this.state.challenge.challenge_id}`}>
            <button
              // disabled={this.isButtonDisabled()}
              className={`btn w-full btn-alps mt-20`}
            >
              {!this.isButtonDisabled()
                ? "Tageseintrag hinzufügen"
                : "Weitere Tageseinträge hinzufügen"}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(ChallengeOverview);
