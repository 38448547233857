import { WebPushDeviceBrowserEnum } from "../api/generated";

function urlBase64ToUint8Array(base64String: string) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function loadVersionBrowser(userAgent: string) {
  var ua = userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
}

export type PushNotificationsData = {
  browser: WebPushDeviceBrowserEnum;
  p256dh: string;
  auth: string;
  name: string;
  registration_id: string;
};

export async function registerServiceWorkerAndSubscribePushAPI(
  applicationServerKey: string,
  user_id: string,
  registration_done: (data: PushNotificationsData) => void,
  registration_failed?: () => void
) {
  // Code copy + pasta from: https://github.com/jazzband/django-push-notifications
  // In your ready listener
  if ("serviceWorker" in navigator) {
    // The service worker has to store in the root of the app
    // http://stackoverflow.com/questions/29874068/navigator-serviceworker-is-never-ready
    var browser = loadVersionBrowser(navigator.userAgent);
    navigator.serviceWorker
      .register("/navigatorPush.service.js?v=1.0.0")
      .then(function (reg) {
        reg.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
          })

          .then(function (sub) {
            var endpointParts = sub.endpoint.split("/");
            var registration_id = endpointParts[endpointParts.length - 1];
            var data: PushNotificationsData = {
              browser: browser.name.toUpperCase() as WebPushDeviceBrowserEnum,
              p256dh: btoa(
                String.fromCharCode.apply(
                  null,
                  new Uint8Array((sub as any).getKey("p256dh")) as any
                )
              ),
              auth: btoa(
                String.fromCharCode.apply(
                  null,
                  new Uint8Array((sub as any).getKey("auth")) as any
                )
              ),
              name: user_id,
              registration_id: registration_id,
            };
            registration_done(data);
          });
      })
      .catch(function (err) {
        console.log(":^(", err);
      });
  }
}
