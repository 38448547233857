import React, { Component } from "react";
import { ChallengeDetail } from "../../../api/generated";

type ChallengeCodeProps = {
  challenge: ChallengeDetail;
};

export class ChallengeCode extends Component<
  ChallengeCodeProps,
  ChallengeCodeProps
> {
  constructor(props: any) {
    super(props);
    this.state = {
      challenge: props.challenge,
    };
  }
  render() {
    return (
      <div>
        <div className="bg-gray-50 rounded-2xl h-32 flex flex-col justify-center">
          <h1 className="text-center">{this.state.challenge.challenge_id}</h1>
        </div>
        <div className="text-center leading-loose font-bold">
          Schreibe diesen Code für dich auf!
        </div>
        <div className="text-center leading-loose font-bold">
          Diesen brauchst du immer für deine Anmeldungen.
        </div>
      </div>
    );
  }
}

export default ChallengeCode;
