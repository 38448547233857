
const reflexionsFragen = [
    "Wie oft hast du heute schon an deine Challenge gedacht?",
    "Wie kannst du dir die Challenge heute erleichtern?",
    "Worauf bist du heute stolz?",
    "Was ärgert dich in Zusammenhang mit deiner Challenge oder nachhaltigem Handeln?",
    "Welche politischen Entscheidungen könnten dir die Umsetzung deiner Challenge erleichtern?",
    "Wie wichtig ist Nachhaltigkeit für dich? Wie nachhaltig handelst du?",
    "Welche Angewohnheiten möchtest du aufgeben, welche etablieren, und warum?",
    "Wovon wünschst du dir mehr in deinem Alltag? Wovon weniger?",
    "Bist du durch die #30dayschallenge motivierter nachhaltig zu leben?",
    "Könntest du dir vorstellen, die Aufgabe deiner Challenge nach Ablauf der 30 Tage weiterhin in deinen Alltag einzubauen?"
]


export function get_random_daily_question() {
    return reflexionsFragen[Math.floor(Math.random()*reflexionsFragen.length)];
}