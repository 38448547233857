import React, { useState, Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { get_random_daily_question } from "../helper/random_daily_question";
import trioKombiLogoWeiter from "../assets/klimaKombiLogo_alps_sos.png";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import { setDefaultLocale } from "react-datepicker";

import {
  ChallengeApi,
  ChallengeDetail,
  ChallengeEntryApi,
  ChallengeEntryDetail,
} from "../api/generated";
import { AxiosError } from "axios";
import ErrorComponent from "../components/challengeCreate/childrenChallengeCreate/ErrorComponent";
import { getFormatedFullDateString } from "../helper/datetime";
import { textColorForDifficulty } from "../helper/difficulty";
import {
  difficultyForChallengeEntry,
  wasCheatDayWasAlreadyUsed,
} from "../helper/rechart_helper";
import { NavLink } from "react-router-dom";

setDefaultLocale("de");
registerLocale("de", de);

interface RouteParams {
  challenge_id: string;
}

interface CreateChallengeEntryProps extends RouteComponentProps<RouteParams> {}

interface CreateChallengeEntryState {
  currentChallenge: ChallengeDetail;
  challengeEntry: ChallengeEntryDetail;
  errorMessage: {
    visible: boolean;
    message: string;
  };
  challengeEntryForCurrentDateExists: boolean;
  cheatDayWasAlreadyUsed: boolean;
  dailyQuestion: string;
}

export class CreateChallengeEntry extends Component<
  CreateChallengeEntryProps,
  CreateChallengeEntryState
> {
  constructor(props: CreateChallengeEntryProps) {
    super(props);
    this.state = {
      currentChallenge: {
        challenge_id: "",
        description: "",
        name: "",
        start_date: "",
        username: "",
      },
      challengeEntry: {
        challenge_id: "",
        date: "",
        diary_entry: "",
        difficulty: 50,
      },
      errorMessage: {
        visible: false,
        message: "",
      },
      challengeEntryForCurrentDateExists: false,
      cheatDayWasAlreadyUsed: false,
      dailyQuestion: get_random_daily_question(),
    };
    this.submitEntry = this.submitEntry.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }
  componentDidMount() {
    let challengeApi = new ChallengeApi();
    challengeApi
      .challengeRead(this.props.match.params.challenge_id)
      .then((challenge) => {
        this.setState((state, _) => ({
          currentChallenge: challenge.data,
          challengeEntry: {
            ...state.challengeEntry,
            challenge_id: challenge.data.id as string,
            date: new Date().toISOString(),
          },
        }));
        let challengeEntryApi = new ChallengeEntryApi();
        challengeEntryApi
          .challengeEntryListRead(challenge.data.challenge_id as string)
          .then((challengeEntries) => {
            if (
              difficultyForChallengeEntry(challengeEntries.data, new Date())
            ) {
              console.log(wasCheatDayWasAlreadyUsed(challengeEntries.data));
              this.setState({
                challengeEntryForCurrentDateExists: true,
                cheatDayWasAlreadyUsed: wasCheatDayWasAlreadyUsed(
                  challengeEntries.data
                ),
              });
            }
          });
      });
  }

  submitEntry() {
    // Validate input data
    if (
      !this.state.challengeEntry.diary_entry &&
      this.state.challengeEntry.difficulty > 75
    ) {
      console.log(this.state.challengeEntry.diary_entry);
      this.displayErrorMessage(
        "Bitte fülle das Notizfeld aus um besser zu verstehen warum dir die Challenge heute besonders schwer gefallen ist."
      );
      return;
    }
    const challengeEntryApi = new ChallengeEntryApi({
      isJsonMime: () => false,
    });
    challengeEntryApi
      .challengeEntryCreate(this.state.challengeEntry)
      .then((success) => {
        this.props.history.push(
          `/challenge/${this.state.currentChallenge.challenge_id}`
        );
      })
      .catch((error: AxiosError) => {
        // Catch error, check if entry for this date already exists
      });
  }

  dismissError() {
    this.setState({
      errorMessage: {
        visible: false,
        message: "",
      },
    });
  }

  displayErrorMessage(message: string) {
    this.setState({
      errorMessage: {
        message: message,
        visible: true,
      },
    });
  }

  render() {
    return (
      <div>
        <NavLink to={"/"}>
          <img
            className="trioKombiLogo"
            src={trioKombiLogoWeiter}
            alt="Challenge Logo der 30 Days Klima Challenge"
          />
        </NavLink>
        <div className="bg-gray-50 rounded-2xl p-4 flex flex-col">
          <div className="flex w-full">
            <div className="flex flex-col w-full pt-4">
              <h4 className="text-3xl text-center mb-4">Tageseintrag</h4>
              <div className="bg-gray-50 text-center font-bold">
                {/* {getFormatedFullDateString(this.state.challengeEntry.date)} */}
                <DatePicker
                  locale="de"
                  dateFormat="dd.MM.yyyy"
                  className="input input-bordered w-7/12 text-center w-2/5"
                  selected={
                    this.state.challengeEntry.date == ""
                      ? new Date()
                      : new Date(this.state.challengeEntry.date)
                  }
                  minDate={new Date(this.state.currentChallenge.start_date)}
                  maxDate={
                    new Date(
                      new Date().setDate(
                        new Date(
                          this.state.currentChallenge.start_date
                        ).getDate() + 30
                      )
                    )
                  }
                  onChange={(date) =>
                    this.setState((state, _) => ({
                      challengeEntry: {
                        ...state.challengeEntry,
                        date: date?.toISOString() ? date?.toISOString() : "",
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <hr className="mt-4 mb-8 border-gray-400" />
          <p className="text-center text-2xl mt-4 mb-8">
            {this.state.currentChallenge.name}
          </p>
          <p className="text-gray-600 uppercase font-bold text-l mt-8 ml-8">
            Wie leicht ist es dir heute gefallen, dein Ziel zu erreichen?
          </p>
          {!this.state.challengeEntry.cheat_day ? (
            <div className="flex w-full justify-between mt-8 ">
              <div className={`font-bold p-1 ${textColorForDifficulty(1)}`}>
                {/* Nicht geschafft */}
                🙁
              </div>
              <div
                className={`${
                  this.state.challengeEntry.difficulty > 25
                    ? "font-bold p-1 " + textColorForDifficulty(26)
                    : "p-1"
                } `}
              >
                {/* Gerade so geschafft */}
                😐
              </div>
              <div
                className={`${
                  this.state.challengeEntry.difficulty > 50
                    ? "font-bold p-1 " + textColorForDifficulty(51)
                    : "p-1"
                } `}
              >
                {/* Geschafft */}
                🙂
              </div>
              <div
                className={`${
                  this.state.challengeEntry.difficulty > 75
                    ? "font-bold p-1 " + textColorForDifficulty(76)
                    : "p-1"
                } `}
              >
                {/* Leicht geschafft */}
                😀
              </div>
            </div>
          ) : (
            ""
          )}
          {!this.state.challengeEntry.cheat_day ? (
            <input
              disabled={this.state.challengeEntry.cheat_day}
              type="range"
              max="100"
              value={this.state.challengeEntry.difficulty}
              onChange={(event) =>
                this.setState((state, _) => ({
                  challengeEntry: {
                    ...state.challengeEntry,
                    difficulty: Number(event.target.value),
                  },
                }))
              }
              className="range range-primary"
            />
          ) : (
            ""
          )}
          {!this.state.cheatDayWasAlreadyUsed ? (
            <div className="p-6 card mt-8 border-firstly border-2">
              <div className="form-control">
                <label className="cursor-pointer label">
                  <span className="font-bold">Cheatday einsetzen</span>
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    className="checkbox checkbox-primary"
                    onChange={(event) =>
                      this.setState((state, _) => ({
                        challengeEntry: {
                          ...state.challengeEntry,
                          cheat_day: event.target.checked,
                        },
                      }))
                    }
                  />
                </label>
              </div>
            </div>
          ) : (
            <div className="my-12 text-center">
              Du hast deinen Cheatday für diese Challenge bereits eingesetzt.
            </div>
          )}
          <p className="text-gray-600 uppercase font-bold text-l mt-8 ml-8">
            Notiz
          </p>

          <div className="form-control mt-4">
            <textarea
              className="textarea h-24 textarea-bordered"
              placeholder={this.state.dailyQuestion}
              onChange={(event) =>
                this.setState((state, _) => ({
                  challengeEntry: {
                    ...state.challengeEntry,
                    diary_entry: event.target.value,
                  },
                }))
              }
            ></textarea>
          </div>
          {this.state.errorMessage.visible ? (
            <ErrorComponent
              onDismiss={this.dismissError}
              errorMessage={this.state.errorMessage.message}
            />
          ) : (
            ""
          )}
          <button
            onClick={this.submitEntry}
            className="btn btn-lg btn-alps mt-16"
            // disabled={this.state.challengeEntryForCurrentDateExists}
          >
            {!this.state.challengeEntryForCurrentDateExists
              ? `Tageseintrag hinzufügen`
              : `Tageseintrag hinzufügen`}
          </button>
          <button
            onClick={() =>
              this.props.history.push(
                `/challenge/${this.state.currentChallenge.challenge_id}`
              )
            }
            className="btn btn-lg mt-4"
          >
            Zurück zur Challenge
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateChallengeEntry);
