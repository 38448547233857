import { ChallengeDetail, ChallengeEntryDetail } from "../api/generated";
import { areSameDay, getDates } from "./datetime";

export interface ChallengeSettings {
  pushNotificationsAllowed: boolean;
}

function getChallengeSettings(challengeID: string): ChallengeSettings | null {
  let challengeSettingsString = localStorage.getItem(challengeID);

  if (challengeSettingsString) {
    let challengeSettings: ChallengeSettings = JSON.parse(
      challengeSettingsString
    );
    return challengeSettings;
  }
  return null;
}

export function challengeSettingsPushAllowed(challengeID: string): boolean {
  let challengeSettings = getChallengeSettings(challengeID);
  if (challengeSettings) {
    return challengeSettings.pushNotificationsAllowed;
  }
  // When object is not yet initialized, assume user wants push notifications
  return true;
}

export function setPushNotificationsStatusForChallenge(
  challengeID: string,
  status: boolean
): void {
  let challengeSettings = getChallengeSettings(challengeID);
  if (challengeSettings) {
    challengeSettings.pushNotificationsAllowed = status;
    localStorage.setItem(challengeID, JSON.stringify(challengeSettings));
  } else {
    challengeSettings = {
      pushNotificationsAllowed: status,
    };
    localStorage.setItem(challengeID, JSON.stringify(challengeSettings));
  }
}

export function removeDuplicatesFromChallengeEntries(
  challengeEntries: ChallengeEntryDetail[],
  challenge: ChallengeDetail
): ChallengeEntryDetail[] {
  // Create array of possible dates in challenge time range [1-1-2020, 1-1-2020, 2-1-2020, ..., 30-1-2020]
  // Get entries for each possible date - search duplicate [1-1-2020, 1-1-2020]
  // Filter entry for highest return with newest time [1-1-2020 time 1, 1-1-2020 time 2], return [1-1-2020 time 1]
  if (!challenge.duration) {
    return [];
  }
  const dates = getDates(new Date(challenge.start_date), challenge.duration);
  let result: ChallengeEntryDetail[] = [];
  for (let date of dates) {
    let filtered = challengeEntries.filter((challengeEntry) =>
      areSameDay(new Date(challengeEntry.date), date)
    );

    if (filtered.length > 0) {
      let tmp = filtered.reduce((a, b) => {
        return new Date(a.created_at as string) >
          new Date(b.created_at as string)
          ? a
          : b;
      });
      result.push(tmp);
    }
  }

  return result;
}
