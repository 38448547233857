import React, { Component } from "react";
import { ChallengeDetail } from "../../../api/generated";

interface UsernameState {
  username: string;
  isEmployee: boolean;
}

interface UsernameProps {
  challenge: ChallengeDetail;
}

export class UserName extends Component<UsernameProps, UsernameState> {
  constructor(props: any) {
    super(props);
    this.state = {
      username: this.props.challenge.username,
      isEmployee: false,
    } as UsernameState;
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangeIsEmployee = this.handleChangeIsEmployee.bind(this);
  }
  handleChangeUsername(event: any) {
    this.setState({ username: event.target.value });
  }

  handleChangeIsEmployee(event: any) {
    this.setState({ isEmployee: event.target.checked });
  }

  render() {
    return (
      <div>
        {/* input field for username */}
        <div className="form-control">
          <label className="label">
            <span>
              Gib uns deinen Namen bekant wie wir dich ansprechen dürfen
            </span>
          </label>
          <input
            type="text"
            placeholder="Alex Mustermensch"
            className="input input-bordered"
            value={this.state.username}
            onChange={this.handleChangeUsername}
          />
          <div className="flex mt-5">
            <div>Ich bin SOS Kinderdorf Mitarbeiter</div>
            <input
              type="checkbox"
              checked={this.state.isEmployee}
              onChange={this.handleChangeIsEmployee}
              className="checkbox checkbox-primary ml-4 border-firstly checked:bg-gray-600 checked:border-firstly self-center"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UserName;
