/* tslint:disable */
/* eslint-disable */
/**
 * Habit Tracker REST API
 * Habit Tracker REST API for the AlpS #30dayschallenge
 *
 * The version of the OpenAPI document: v1
 * Contact: lukas@moelschl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ChallengeDetail
 */
export interface ChallengeDetail {
    /**
     * 
     * @type {string}
     * @memberof ChallengeDetail
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeDetail
     */
    challenge_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeDetail
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeDetail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeDetail
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeDetail
     */
    username: string;
    /**
     * 
     * @type {number}
     * @memberof ChallengeDetail
     */
    duration?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChallengeDetail
     */
    is_employee?: boolean;
}
/**
 * 
 * @export
 * @interface ChallengeEntryDetail
 */
export interface ChallengeEntryDetail {
    /**
     * 
     * @type {string}
     * @memberof ChallengeEntryDetail
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeEntryDetail
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof ChallengeEntryDetail
     */
    difficulty: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChallengeEntryDetail
     */
    cheat_day?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChallengeEntryDetail
     */
    diary_entry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChallengeEntryDetail
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeEntryDetail
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeEntryDetail
     */
    challenge_id: string;
}
/**
 * 
 * @export
 * @interface DeviceIsAlreadyRegistered
 */
export interface DeviceIsAlreadyRegistered {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceIsAlreadyRegistered
     */
    is_registered: boolean;
}
/**
 * 
 * @export
 * @interface PredefinedChallenge
 */
export interface PredefinedChallenge {
    /**
     * 
     * @type {string}
     * @memberof PredefinedChallenge
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedChallenge
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedChallenge
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof PredefinedChallenge
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof PredefinedChallenge
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedChallenge
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface WebPushDevice
 */
export interface WebPushDevice {
    /**
     * 
     * @type {string}
     * @memberof WebPushDevice
     */
    registration_id: string;
    /**
     * Currently only support to Chrome, Firefox, Edge and Opera browsers
     * @type {string}
     * @memberof WebPushDevice
     */
    browser?: WebPushDeviceBrowserEnum;
    /**
     * 
     * @type {string}
     * @memberof WebPushDevice
     */
    auth: string;
    /**
     * 
     * @type {string}
     * @memberof WebPushDevice
     */
    p256dh: string;
    /**
     * 
     * @type {string}
     * @memberof WebPushDevice
     */
    name?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum WebPushDeviceBrowserEnum {
    Chrome = 'CHROME',
    Firefox = 'FIREFOX',
    Opera = 'OPERA',
    Edge = 'EDGE'
}


/**
 * ChallengeApi - axios parameter creator
 * @export
 */
export const ChallengeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChallengeDetail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeCreateCreate: async (data: ChallengeDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('challengeCreateCreate', 'data', data)
            const localVarPath = `/challenge/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeRead: async (challengeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'challengeId' is not null or undefined
            assertParamExists('challengeRead', 'challengeId', challengeId)
            const localVarPath = `/challenge/{challenge_id}`
                .replace(`{${"challenge_id"}}`, encodeURIComponent(String(challengeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChallengeApi - functional programming interface
 * @export
 */
export const ChallengeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChallengeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChallengeDetail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challengeCreateCreate(data: ChallengeDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challengeCreateCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challengeRead(challengeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challengeRead(challengeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChallengeApi - factory interface
 * @export
 */
export const ChallengeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChallengeApiFp(configuration)
    return {
        /**
         * 
         * @param {ChallengeDetail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeCreateCreate(data: ChallengeDetail, options?: any): AxiosPromise<ChallengeDetail> {
            return localVarFp.challengeCreateCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeRead(challengeId: string, options?: any): AxiosPromise<ChallengeDetail> {
            return localVarFp.challengeRead(challengeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChallengeApi - object-oriented interface
 * @export
 * @class ChallengeApi
 * @extends {BaseAPI}
 */
export class ChallengeApi extends BaseAPI {
    /**
     * 
     * @param {ChallengeDetail} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengeApi
     */
    public challengeCreateCreate(data: ChallengeDetail, options?: any) {
        return ChallengeApiFp(this.configuration).challengeCreateCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} challengeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengeApi
     */
    public challengeRead(challengeId: string, options?: any) {
        return ChallengeApiFp(this.configuration).challengeRead(challengeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChallengeEntryApi - axios parameter creator
 * @export
 */
export const ChallengeEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChallengeEntryDetail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeEntryCreate: async (data: ChallengeEntryDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('challengeEntryCreate', 'data', data)
            const localVarPath = `/challenge_entry/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeEntryListRead: async (challengeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'challengeId' is not null or undefined
            assertParamExists('challengeEntryListRead', 'challengeId', challengeId)
            const localVarPath = `/challenge_entry/list/{challenge_id}`
                .replace(`{${"challenge_id"}}`, encodeURIComponent(String(challengeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this challenge entry model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeEntryRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('challengeEntryRead', 'id', id)
            const localVarPath = `/challenge_entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChallengeEntryApi - functional programming interface
 * @export
 */
export const ChallengeEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChallengeEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChallengeEntryDetail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challengeEntryCreate(data: ChallengeEntryDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeEntryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challengeEntryCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challengeEntryListRead(challengeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChallengeEntryDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challengeEntryListRead(challengeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this challenge entry model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challengeEntryRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeEntryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challengeEntryRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChallengeEntryApi - factory interface
 * @export
 */
export const ChallengeEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChallengeEntryApiFp(configuration)
    return {
        /**
         * 
         * @param {ChallengeEntryDetail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeEntryCreate(data: ChallengeEntryDetail, options?: any): AxiosPromise<ChallengeEntryDetail> {
            return localVarFp.challengeEntryCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeEntryListRead(challengeId: string, options?: any): AxiosPromise<Array<ChallengeEntryDetail>> {
            return localVarFp.challengeEntryListRead(challengeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this challenge entry model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeEntryRead(id: string, options?: any): AxiosPromise<ChallengeEntryDetail> {
            return localVarFp.challengeEntryRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChallengeEntryApi - object-oriented interface
 * @export
 * @class ChallengeEntryApi
 * @extends {BaseAPI}
 */
export class ChallengeEntryApi extends BaseAPI {
    /**
     * 
     * @param {ChallengeEntryDetail} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengeEntryApi
     */
    public challengeEntryCreate(data: ChallengeEntryDetail, options?: any) {
        return ChallengeEntryApiFp(this.configuration).challengeEntryCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} challengeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengeEntryApi
     */
    public challengeEntryListRead(challengeId: string, options?: any) {
        return ChallengeEntryApiFp(this.configuration).challengeEntryListRead(challengeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this challenge entry model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengeEntryApi
     */
    public challengeEntryRead(id: string, options?: any) {
        return ChallengeEntryApiFp(this.configuration).challengeEntryRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PredefinedChallengeApi - axios parameter creator
 * @export
 */
export const PredefinedChallengeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedChallengeListList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/predefined_challenge/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredefinedChallengeApi - functional programming interface
 * @export
 */
export const PredefinedChallengeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PredefinedChallengeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedChallengeListList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PredefinedChallenge>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedChallengeListList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PredefinedChallengeApi - factory interface
 * @export
 */
export const PredefinedChallengeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PredefinedChallengeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedChallengeListList(options?: any): AxiosPromise<Array<PredefinedChallenge>> {
            return localVarFp.predefinedChallengeListList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PredefinedChallengeApi - object-oriented interface
 * @export
 * @class PredefinedChallengeApi
 * @extends {BaseAPI}
 */
export class PredefinedChallengeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedChallengeApi
     */
    public predefinedChallengeListList(options?: any) {
        return PredefinedChallengeApiFp(this.configuration).predefinedChallengeListList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushnotificationsApi - axios parameter creator
 * @export
 */
export const PushnotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} challengeId 
         * @param {WebPushDevice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushnotificationsChallengeCreate: async (challengeId: string, data: WebPushDevice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'challengeId' is not null or undefined
            assertParamExists('pushnotificationsChallengeCreate', 'challengeId', challengeId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('pushnotificationsChallengeCreate', 'data', data)
            const localVarPath = `/pushnotifications/challenge/{challenge_id}`
                .replace(`{${"challenge_id"}}`, encodeURIComponent(String(challengeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {WebPushDevice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushnotificationsExistsCreate: async (challengeId: string, data: WebPushDevice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'challengeId' is not null or undefined
            assertParamExists('pushnotificationsExistsCreate', 'challengeId', challengeId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('pushnotificationsExistsCreate', 'data', data)
            const localVarPath = `/pushnotifications/exists/{challenge_id}`
                .replace(`{${"challenge_id"}}`, encodeURIComponent(String(challengeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushnotificationsApi - functional programming interface
 * @export
 */
export const PushnotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushnotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} challengeId 
         * @param {WebPushDevice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pushnotificationsChallengeCreate(challengeId: string, data: WebPushDevice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredefinedChallenge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pushnotificationsChallengeCreate(challengeId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {WebPushDevice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pushnotificationsExistsCreate(challengeId: string, data: WebPushDevice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceIsAlreadyRegistered>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pushnotificationsExistsCreate(challengeId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushnotificationsApi - factory interface
 * @export
 */
export const PushnotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushnotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} challengeId 
         * @param {WebPushDevice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushnotificationsChallengeCreate(challengeId: string, data: WebPushDevice, options?: any): AxiosPromise<PredefinedChallenge> {
            return localVarFp.pushnotificationsChallengeCreate(challengeId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} challengeId 
         * @param {WebPushDevice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushnotificationsExistsCreate(challengeId: string, data: WebPushDevice, options?: any): AxiosPromise<DeviceIsAlreadyRegistered> {
            return localVarFp.pushnotificationsExistsCreate(challengeId, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushnotificationsApi - object-oriented interface
 * @export
 * @class PushnotificationsApi
 * @extends {BaseAPI}
 */
export class PushnotificationsApi extends BaseAPI {
    /**
     * 
     * @param {string} challengeId 
     * @param {WebPushDevice} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushnotificationsApi
     */
    public pushnotificationsChallengeCreate(challengeId: string, data: WebPushDevice, options?: any) {
        return PushnotificationsApiFp(this.configuration).pushnotificationsChallengeCreate(challengeId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} challengeId 
     * @param {WebPushDevice} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushnotificationsApi
     */
    public pushnotificationsExistsCreate(challengeId: string, data: WebPushDevice, options?: any) {
        return PushnotificationsApiFp(this.configuration).pushnotificationsExistsCreate(challengeId, data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebpushhookApi - axios parameter creator
 * @export
 */
export const WebpushhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webpushhook45e9a28e306b41258db2798f5a84294aCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webpushhook/45e9a28e-306b-4125-8db2-798f5a84294a`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebpushhookApi - functional programming interface
 * @export
 */
export const WebpushhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebpushhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webpushhook45e9a28e306b41258db2798f5a84294aCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webpushhook45e9a28e306b41258db2798f5a84294aCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebpushhookApi - factory interface
 * @export
 */
export const WebpushhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebpushhookApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webpushhook45e9a28e306b41258db2798f5a84294aCreate(options?: any): AxiosPromise<void> {
            return localVarFp.webpushhook45e9a28e306b41258db2798f5a84294aCreate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebpushhookApi - object-oriented interface
 * @export
 * @class WebpushhookApi
 * @extends {BaseAPI}
 */
export class WebpushhookApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebpushhookApi
     */
    public webpushhook45e9a28e306b41258db2798f5a84294aCreate(options?: any) {
        return WebpushhookApiFp(this.configuration).webpushhook45e9a28e306b41258db2798f5a84294aCreate(options).then((request) => request(this.axios, this.basePath));
    }
}


