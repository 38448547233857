export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * 1000;
export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 60 * 60 * 24 * 1000;

export function getFormatedDateString(date: string): string {
  return new Intl.DateTimeFormat("de-AT", {
    // dateStyle: dateStyle,
    day: "2-digit",
    month: "long",
  }).format(new Date(date));
}

export function getFormatedFullDateString(date: string): string {
  if (!date) {
    return "";
  }
  return new Intl.DateTimeFormat("de-AT", {
    dateStyle: "short",
    // day: "2-digit",
    // month: "long",
  }).format(new Date(date));
}

// https://stackoverflow.com/questions/4413590/javascript-get-array-of-dates-between-2-dates
export const getDates = (from: Date, dateRange: number): Date[] => {
  const to = new Date(from.getTime() + ONE_DAY * dateRange);

  let daysArr = [new Date(from)];
  let tempDate = from;

  while (tempDate < to) {
    tempDate = new Date(tempDate.getTime() + ONE_DAY);
    daysArr.push(tempDate);
  }

  return daysArr;
};

export const getDateTimeDifference = (from: Date, dateRange: number) => {
  const to = new Date(from.getTime() + ONE_DAY * dateRange);
  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);
  const now = new Date();

  const difference = to.getTime() - now.getTime();

  return {
    days: Math.trunc(difference / ONE_DAY),
    hours: Math.trunc(difference / ONE_HOUR) % 24,
    minutes: Math.trunc(difference / ONE_MINUTE) % 60,
    seconds: Math.trunc(difference / ONE_SECOND) % 60,
  };
};

export const getToDateForChallenge = (from: Date, dateRange: number): Date => {
  return new Date(from.getTime() + ONE_DAY * dateRange);
};

export const areSameDay = (date1: Date, date2: Date): boolean => {
  let tmp =
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
  return tmp;
};
