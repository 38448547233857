import React, { Component } from "react";

interface ErorrComponentProps {
  errorMessage: string;
  onDismiss(): any;
}

interface ErrorComponentState {}

export class ErrorComponent extends Component<
  ErorrComponentProps,
  ErrorComponentState
> {
  constructor(props: ErorrComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mb-8">
        <div className="alert alert-error flex-row">
          <div className="flex-1">
            <label>{this.props.errorMessage}</label>
          </div>
          <div className="flex-none">
            <button
              onClick={this.props.onDismiss}
              className="btn btn-sm btn-ghost"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorComponent;
