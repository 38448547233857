import React, { Component } from "react";

interface IndividualChallengeState {
  challengeName: string;
  challengeDescription: string;
}

export class IndividualChallenge extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      challengeName: this.props.challenge.name,
      challengeDescription: this.props.challenge.description,
    } as IndividualChallengeState;

    this.handleChangeChallengeName = this.handleChangeChallengeName.bind(this);
    this.handleChangeChallengeDescription =
      this.handleChangeChallengeDescription.bind(this);
  }

  handleChangeChallengeName(event: any) {
    this.setState({ challengeName: event.target.value });
  }

  handleChangeChallengeDescription(event: any) {
    this.setState({ challengeDescription: event.target.value });
  }

  render() {
    return (
      <div className="space-y-8">
        {/* input name of challenge */}
        <div className="form-control">
          <label className="label">
            <span>Name der Challenge</span>
          </label>
          <input
            type="text"
            placeholder="Welches Ziel möchtest du erreichen?"
            className="input input-bordered"
            onChange={this.handleChangeChallengeName}
            value={this.state.challengeName}
          />
        </div>
        {/* textarea for description */}
        <div className="form-control">
          <label className="label">
            <span>
              Beschreibe in ein paar kurzen Sätzen deine Ziele für die Challenge
            </span>
          </label>
          <textarea
            className="textarea h-24 textarea-bordered"
            placeholder="In den nächsten 30 Tagen möchte ich ..."
            onChange={this.handleChangeChallengeDescription}
            value={this.state.challengeDescription}
          ></textarea>
        </div>
      </div>
    );
  }
}

export default IndividualChallenge;
