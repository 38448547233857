import React, { Component } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setDefaultLocale } from "react-datepicker";

import de from "date-fns/locale/de";
import { ChallengeDetail } from "../../../api/generated";

setDefaultLocale("de");
registerLocale("de", de);

interface StartDateSelectorState {
  startDate: any;
}

interface StartDateSelectorProps {
  challenge: ChallengeDetail;
}

export class StartDate extends Component<
  StartDateSelectorProps,
  StartDateSelectorState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      startDate: new Date(this.props.challenge.start_date),
    } as StartDateSelectorState;
  }

  render() {
    return (
      <div className="text-center">
        {/* Calendar with Startdate -> no date in the past */}
        <span>Startdatum</span>
        <DatePicker
          locale="de"
          dateFormat="dd.MM.yyyy"
          className="input input-bordered w-7/12 text-center"
          selected={this.state.startDate}
          onChange={(date) => this.setState({ startDate: date })}
          minDate={new Date()}
          placeholderText="Startdatum auswählen"
          // open={true}
        />
      </div>
    );
  }
}
export default StartDate;
