import React from "react";
import "./Splashscreen.scss";

import { Link } from "react-router-dom";

import challengeLogo from "../assets/challengeLogo.svg";
import klimaKombiLogo from "../assets/sos_Kinderdorf.png";

class Splashscreen extends React.Component {
  render() {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <img
          className="challenge-logo h-2/5 mt-auto mb-10"
          src={challengeLogo}
          alt="Challenge Logo der 30 Days Klima Challenge"
        />

        <Link to="/challenge/select">
          <button className="pulse-button btn btn-alps">Los geht's!</button>
        </Link>

        <img
          className="supporters-logo mt-auto"
          src={klimaKombiLogo}
          alt="Logo Sponsoren"
        />
      </div>
    );
  }
}

export default Splashscreen;
