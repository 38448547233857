import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import trioKombiLogo from "../assets/trioKombiLogoWeiter.png";

export class Faq extends Component {
  render() {
    return (
      <div className="flex flex-col justify-start align-middle h-full">
        <NavLink to={"/"}>
          <img
            className="trioKombiLogo"
            src={trioKombiLogo}
            alt="Challenge Logo der 30 Days Klima Challenge"
          />
        </NavLink>
        <h1 className="mt-4">FAQ</h1>
        <p>Die wichtigsten Fragen rund um die #30dayschallenge</p>
      </div>
    );
  }
}

export default Faq;
