import React, { Component } from "react";
import { ChallengeDetail } from "../../../api/generated";

type ChallengeCodeProps = {
  challenge: ChallengeDetail;
  challengeCodeAcceptedClicked(event: any): any;
};

export class ChallengeCodeConfirm extends Component<ChallengeCodeProps, any> {
  constructor(props: ChallengeCodeProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="bg-gray-50 rounded-2xl h-32 flex flex-col justify-center">
          <h1 className="text-center">{this.props.challenge.challenge_id}</h1>
        </div>
        <div className="text-center leading-loose font-bold flex flex-row justify-center align-middle mt-8">
          <div>
            Ich habe mir den Code aufgeschrieben oder einen Screenshot gemacht
          </div>
          <input
            type="checkbox"
            onClick={this.props.challengeCodeAcceptedClicked}
            className="checkbox checkbox-primary ml-4 border-firstly checked:bg-gray-600 checked:border-firstly self-center"
          />
        </div>
      </div>
    );
  }
}

export default ChallengeCodeConfirm;
