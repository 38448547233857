import { ChallengeEntryDetail } from "../api/generated";
import { areSameDay } from "./datetime";
import { nameForDifficulty } from "./difficulty";

export const formatYLabels = (value: any, index: number): string => {
  if (value === 25 || value === 50 || value === 75 || value === 100) {
    return nameForDifficulty(value - 1);
  }
  return "";
};

export const formatXLabels = (value: any, index: number): string => {
  if (value === "auto") {
    return "";
  }
  return new Intl.DateTimeFormat("de-AT", {
    day: "numeric",
    month: "numeric",
  }).format(new Date(value));
};

export const difficultyForChallengeEntry = (
  challengeEntries: ChallengeEntryDetail[],
  date: Date
): number | undefined => {
  let filtered = challengeEntries.filter((challengeEntry) =>
    areSameDay(new Date(challengeEntry.date), date)
  );

  if (filtered.length > 0) {
    if (filtered[0].cheat_day) {
      return undefined;
    }
    return filtered[0].difficulty;
  }
  return undefined;
};

export const wasCheatDayWasAlreadyUsed = (
  challengeEntries: ChallengeEntryDetail[]
): boolean => {
  return challengeEntries.some((entry) => entry.cheat_day);
};
