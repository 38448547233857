import { AxiosError } from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { ChallengeApi } from "../api/generated";

import { Link, NavLink } from "react-router-dom";

import trioKombiLogoWeiter from "../assets/klimaKombiLogo_alps_sos.png";

export class ChallengeEnterOrCreate extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { challengeIdValue: "", challengeCodeValid: true };

    this.handleChange = this.handleChange.bind(this);
    this.checkIfChallengeExists = this.checkIfChallengeExists.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  handleChange(event: any) {
    this.setState({
      challengeIdValue: event.target.value,
      challengeCodeValid: true,
    });
  }

  onKeyUp(event: any) {
    if (event.charCode === 13) {
      this.checkIfChallengeExists();
    }
  }

  checkIfChallengeExists() {
    let challengeApi = new ChallengeApi();
    challengeApi
      .challengeRead(this.state.challengeIdValue)
      .then((challenge) => {
        // Redirect user to challenge overview
        this.props.history.push(`/challenge/${challenge.data.challenge_id}`);
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError) {
          if (error.response?.status === 404) {
            console.error("Diese Challenge existiert nicht. ");
            this.setState(() => ({
              challengeCodeValid: false,
            }));
          }
        } else {
          console.error(error);
          console.error("Something went wrong with this request");
        }
      });
  }
  render() {
    return (
      <div className="flex flex-col min-h-screen">
        {/* Logo */}
        <div className="block flex-grow-0">
          <NavLink to={"/"}>
            <img
              className="trioKombiLogo"
              src={trioKombiLogoWeiter}
              alt="Challenge Logo der 30 Days Klima Challenge"
            />
          </NavLink>
        </div>
        {/* Content */}
        <div className="flex justify-center flex-col h-full justify-self-center flex-grow space-y-8">
          <div className="bg-gray-50 rounded-2xl p-4 flex flex-col text-center items-center h-64 justify-center">
            <h1>Nehme die #30dayschallenge an!</h1>
            <Link to="/challenge/create">
              <button className="btn btn-wide btn-lg btn-alps mt-8 self-center">
                Challenge erstellen
              </button>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="flex-grow bg bg-gray-300 h-0.5"></div>
            <div className="flex-grow-0 mx-5">oder</div>
            <div className="flex-grow bg bg-gray-300 h-0.5"></div>
          </div>

          <div className="bg-gray-50 rounded-2xl p-4 flex flex-col text-center h-64 justify-center">
            <h1 className="mb-8">Du hast bereits eine Challenge?</h1>
            <div className="form-control">
              {/* <label className="label">
                <span className="label-text">Username</span>
              </label> */}
              <div className="relative">
                <input
                  placeholder="Gib deinen Code ein"
                  className={`w-full pr-16 input input-firstly input-bordered ${
                    !this.state.challengeCodeValid ? "input-error" : ""
                  }`}
                  type="text"
                  value={this.state.value}
                  onChange={this.handleChange}
                  onKeyPress={this.onKeyUp}
                />
                {!this.state.challengeCodeValid && (
                  <label className="label">
                    <span className="label-text-alt">
                      Challenge exisitert nicht
                    </span>
                  </label>
                )}
                <button
                  onClick={this.checkIfChallengeExists}
                  className="btn-alps-no-rounded absolute top-0 right-0 rounded-l-none btn btn-primary bg-blue-500 border-blue-500 hover:border-gray-400 hover:bg-gray-600"
                >
                  go
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChallengeEnterOrCreate);
