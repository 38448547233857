import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import ChallengeOverview from "./sites/WithChallengeCode/ChallengeOverview";
import Splashscreen from "./sites/Splashscreen";
import ChallengeEnterOrCreate from "./sites/ChallengeEnterOrCreate";
import ChallengeCreateSite from "./components/challengeCreate/ChallengeCreateSite";
import NotFound from "./sites/NotFound";
import CreateChallengeEntry from "./ChallengeEntry/CreateChallengeEntry";
import Faq from "./sites/Faq";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="container mx-auto h-full">
        <Switch>
          <Route path="/challenge/select">
            <ChallengeEnterOrCreate />
          </Route>
          <Route path="/challenge/create">
            <ChallengeCreateSite />
          </Route>
          <Route path="/challenge/entry/:challenge_id">
            <CreateChallengeEntry />
          </Route>
          <Route path="/challenge/:challenge_id">
            <ChallengeOverview />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/not-found">
            <NotFound />
          </Route>

          <Route path="/">
            <Splashscreen />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
