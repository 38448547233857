import React, { Component } from "react";
import Selector from "./childrenChallengeCreate/Selector";
import IndividualChallenge from "./childrenChallengeCreate/IndividualChallenge";
import StartDate from "./childrenChallengeCreate/StartDate";
import UserName from "./childrenChallengeCreate/UserName";

//import assets
import trioKombiLogo from "../../assets/klimaKombiLogo_alps_sos.png";
import { withRouter } from "react-router";
import ChallengeCode from "./childrenChallengeCreate/ChallengeCode";
import { ChallengeApi, ChallengeDetail } from "../../api/generated";
import ChallengeCodeConfirm from "./childrenChallengeCreate/ChallengeCodeConfirm";
import ErrorComponent from "./childrenChallengeCreate/ErrorComponent";
import { NavLink } from "react-router-dom";

interface ChallengeCreateState {
  step: number;
  currentChallenge: ChallengeDetail;
  errorMessage: {
    visible: boolean;
    errorMessage: string;
  };
  codeAccepted: boolean;
}

export class ChallengeCreateSite extends Component<any, ChallengeCreateState> {
  challengeCodeRef: React.RefObject<any>;
  individualChallengeRef: React.RefObject<any>;
  startDateRef: React.RefObject<any>;
  userNameRef: React.RefObject<any>;
  selectorRef: React.RefObject<any>;

  headlines = [
    "Wähle eine Challenge aus",
    "Passe deine Challenge an",
    "Wähle dein Startdatum aus",
    "Gib deinen Namen ein",
    "Das ist dein persönlicher Challenge",
    "Das ist dein persönlicher Challenge",
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      currentChallenge: {
        start_date: new Date().toISOString(),
      },
      errorMessage: {
        visible: false,
        errorMessage: "",
      },
      codeAccepted: false,
    } as ChallengeCreateState;

    this.challengeCodeRef = React.createRef();
    this.individualChallengeRef = React.createRef();
    this.startDateRef = React.createRef();
    this.userNameRef = React.createRef();
    this.selectorRef = React.createRef();

    this.nextStepClick = this.nextStepClick.bind(this);
    this.previousStepClick = this.previousStepClick.bind(this);
    this.onCustomChallengeClick = this.onCustomChallengeClick.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.challengeCodeAcceptedClicked =
      this.challengeCodeAcceptedClicked.bind(this);
  }

  componentDidMount() {}

  challengeCodeAcceptedClicked(event: any) {
    this.setState({ codeAccepted: event.target.checked });
  }

  nextStepClick(event: any, direction = 1) {
    const nextStep = () => {
      this.dismissError();
      this.setState((state, _) => ({
        step: Math.min(5, Math.max(state.step + 1 * direction, 0)),
      }));
    };

    // Disable validation if user wants to go backwards
    if (direction === -1) {
      nextStep();
    }

    if (this.state.step === 0) {
      if (event.target.value === "custom-challenge") {
        this.setState((state, _) => ({
          currentChallenge: {
            ...state.currentChallenge,
            name: "",
            description: "",
          },
        }));
      } else {
        const selectedChallenge: ChallengeDetail =
          this.selectorRef.current.state.selectedChallenge;
        this.setState((state, _) => ({
          currentChallenge: {
            ...state.currentChallenge,
            name: selectedChallenge.name,
            description: selectedChallenge.description,
          },
        }));
      }
      nextStep();
    } else if (this.state.step === 1) {
      const challengeName =
        this.individualChallengeRef.current.state.challengeName;
      const challengeDescription =
        this.individualChallengeRef.current.state.challengeDescription;
      if (!challengeName) {
        this.displayErrorMessage("Bitte gib einen Namen für die Challenge ein");
        return;
      }
      if (!challengeDescription) {
        this.displayErrorMessage(
          "Bitte gib eine Beschreibug für die Challenge ein"
        );
        return;
      }
      this.setState((state, _) => ({
        currentChallenge: {
          ...state.currentChallenge,
          name: challengeName,
          description: challengeDescription,
        },
      }));
      nextStep();
    } else if (this.state.step === 2) {
      const challengeStartDate = (
        this.startDateRef.current.state.startDate as Date
      )
        .toISOString()
        .slice(0, 10);
      this.setState((state, _) => ({
        currentChallenge: {
          ...state.currentChallenge,
          start_date: challengeStartDate,
        },
      }));
      nextStep();
    } else if (this.state.step === 3) {
      const userName = this.userNameRef.current.state.username;
      const isEmployee = this.userNameRef.current.state.isEmployee;
      if (!userName) {
        this.displayErrorMessage("Bitte gib einen Benutzernamen ein");
        return;
      }
      this.setState(
        (state, _) => ({
          currentChallenge: {
            ...state.currentChallenge,
            username: userName,
            is_employee: isEmployee,
          },
        }),
        () => {
          // @ToDo Check if challenge data is fine!
          let challengeApi = new ChallengeApi({
            isJsonMime: (mime) => false,
          });
          challengeApi
            .challengeCreateCreate(this.state.currentChallenge)
            .then((challengeCreated) => {
              this.setState({ currentChallenge: challengeCreated.data });
              nextStep();
            })
            .catch((error) => {
              // @ToDo catch error
            });
        }
      );
    } else if (this.state.step === 4) {
      nextStep();
    } else if (this.state.step === 5) {
      if (!this.state.codeAccepted) {
        this.displayErrorMessage(
          "Bitte vergewissere dich, dass du den Code aufgeschrieben oder einen Screenshot gemacht hast."
        );
        return;
      }
      this.props.history.push(
        `/challenge/${this.state.currentChallenge.challenge_id}`
      );
    }
  }

  displayErrorMessage(message: string) {
    this.setState({
      errorMessage: {
        errorMessage: message,
        visible: true,
      },
    });
  }

  previousStepClick(event: any) {
    this.nextStepClick(event, -1);
  }

  onCustomChallengeClick(event: any) {
    this.nextStepClick(event);
  }

  dismissError() {
    this.setState({
      errorMessage: {
        visible: false,
        errorMessage: "",
      },
    });
  }

  render() {
    return (
      <div
        className={`flex flex-col space-y-8 ${
          this.state.step === 0 ? "h-full" : ""
        }`}
      >
        {/* Logo Trio */}
        <div className="block">
          <NavLink to={"/"}>
            <img
              className="trioKombiLogo"
              src={trioKombiLogo}
              alt="Challenge Logo der 30 Days Klima Challenge"
            />
          </NavLink>
        </div>

        {/* Stepper */}
        {/* bei Handy ist der Stepper zu breit, vl nur 3 Schritte */}
        <div>
          <ul className="w-full steps">
            <li key={0} className={`step step-neutral`}>
              Challenge
            </li>
            <li
              key={1}
              className={`step ${this.state.step > 1 ? "step-neutral" : ""}`}
            >
              Datum
            </li>
            <li
              key={2}
              className={`step ${this.state.step > 2 ? "step-neutral" : ""}`}
            >
              Name
            </li>
            <li
              key={3}
              className={`step ${this.state.step > 3 ? "step-neutral" : ""}`}
            >
              Code
            </li>
          </ul>
        </div>

        {/* Headline */}
        <div>
          {/* text align center geht nit */}
          <p className="!text-center text-xl">
            {" "}
            {this.headlines[this.state.step]}
          </p>
        </div>

        <div className="overflow-y-auto h-full !mb-6">
          {/* dynamische Komponente, als props einbinden? */}
          {this.state.step === 0 && (
            <Selector
              onCustomChallengeClick={this.onCustomChallengeClick}
              ref={this.selectorRef}
            />
          )}
          {this.state.step === 1 && (
            <IndividualChallenge
              ref={this.individualChallengeRef}
              challenge={this.state.currentChallenge}
            />
          )}
          {this.state.step === 2 && (
            <StartDate
              challenge={this.state.currentChallenge}
              ref={this.startDateRef}
            />
          )}
          {this.state.step === 3 && (
            <UserName
              challenge={this.state.currentChallenge}
              ref={this.userNameRef}
            />
          )}
          {this.state.step === 4 && (
            <ChallengeCode challenge={this.state.currentChallenge} />
          )}
          {this.state.step === 5 && (
            <ChallengeCodeConfirm
              challengeCodeAcceptedClicked={this.challengeCodeAcceptedClicked}
              challenge={this.state.currentChallenge}
            />
          )}
        </div>

        {this.state.errorMessage.visible ? (
          <ErrorComponent
            onDismiss={this.dismissError}
            errorMessage={this.state.errorMessage.errorMessage}
          />
        ) : (
          ""
        )}

        {/* Buttons back and forward */}
        {/* padding geht nit */}
        <div className={`flex`}>
          <div className="!pb-8">
            <button
              onClick={this.previousStepClick}
              className={`btn btn-ghost border-gray-400 ${
                this.state.step > 3 ? " hidden " : ""
              }`}
            >
              zurück
            </button>
          </div>
          <div className="ml-auto !pb-8">
            <button onClick={this.nextStepClick} className="btn btn-alps">
              weiter
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChallengeCreateSite);
