export function getInitialsFromUsername(username: string): string {
  let userNameSplit = username.split(" ");
  let initials = "";

  if (userNameSplit.length > 1) {
    initials = userNameSplit[0]?.charAt(0) + userNameSplit[1]?.charAt(0);
  } else {
    initials = username.substring(0, 2);
  }

  return initials.toUpperCase();
}
